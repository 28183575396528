'use client';
import { ModalContextDispatch } from '@/app/_common/modal/modalProvider/ModalContext';
import { useContext } from 'react';
import { _inner, _p, _top, _btn, _div, _table3, _table4 } from '@/app/styles/components/modal/term/privateTerm';

import useIcon from '@/app/_hook/useIcon';

const PrivateTermModal = () => {
    const { IcBack, IcModalClose } = useIcon();
    const { clearModal } = useContext(ModalContextDispatch);

    return (
        <_inner>
            <_top>
                <button onClick={() => clearModal()}>
                    <IcBack size={32} color="#000" />
                </button>
                <p>개인정보처리방침</p>
            </_top>
            <_p>개인정보처리방침</_p>
            <_div>
                <p>
                    {`주식회사 ECF (이하 "회사"라 합니다)은 이용자의 개인정보를 처리함에
          있어 정보주체의 자유와 권리 보호를 위해 개인정보 보호법 및 관계 법령이
          정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고
          있습니다. 이에 개인정보 보호법 제30조에 따라 정보주체에게 개인정보의
          처리와 보호에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을
          신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
          처리방침을 수립·공개합니다.`}
                </p>
                <br />
                <p>제 1 조 [개인정보의 처리목적 및 수집항목]</p>
                <br />
                <p>회사는 다음의 목적으로 정보주체의 동의를 받아 이용자의 개인정보를 수집 및 이용합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다</p>
                <br />
                <span>1. 필수항목</span>
                <_table3 border={1} role="table">
                    <caption>서비스 정보 및 처리 목적</caption>
                    <thead>
                        <tr>
                            <th scope="col">서비스</th>
                            <th scope="col">처리 목적</th>
                            <th scope="col">수집 항목</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" rowSpan={1}>
                                회원 가입
                            </th>
                            <td>
                                <ul>
                                    <li>회원 가입 의사 확인 및 회원관리</li>
                                    <li>(회원제 서비스 제공)</li>
                                    <li>이용자 식별 및 본인 인증</li>
                                    <li>서비스 및 상품 제공에 관한 계약 이행 및 요금정산</li>
                                    <li>회원 정보 자동 업데이트 및 관리</li>
                                    <li>고객 상담 및 불만, 민원 사무 처리</li>
                                    <li>서비스 및 약관 변경 고지, 안내사항 전달</li>
                                    <li>상품∙서비스 이용 실적 정보 통계∙분석</li>
                                    <li>상품∙서비스 개선 및 추천</li>
                                    <li>불법∙부정 이용 방지</li>
                                </ul>
                            </td>
                            <td>아이디(이메일), 비밀번호</td>
                        </tr>
                        <tr>
                            <th scope="row">(SNS 계정)회원 가입 및 간편 로그인 연동</th>
                            <td></td>
                            <td>
                                <ul>
                                    <li>네이버: 이메일, 로그인 정보 식별값</li>
                                    <li>카카오: 이메일, 카카오톡 채널 추가 상태 및 내역, 로그인 정보 식별값</li>
                                    <li>애플: 이메일, 로그인 정보 식별값</li>
                                    <li>구글: 이름, 프로필 사진, 이메일, 로그인 정보 식별값</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">본인인증</th>
                            <td></td>
                            <td>이름, 생년, 생년월일, 성별, 연계정보(CI), 중복가입확인정보(DI)</td>
                        </tr>
                        <tr>
                            <th scope="row">서비스이용</th>
                            <td></td>
                            <td>서비스 이용 내역, 서비스 내 구매 및 결제 내역, 상담 내용</td>
                        </tr>
                        <tr>
                            <th scope="row">고객상담</th>
                            <td></td>
                            <td>이름, 휴대전화번호, 이메일, 상담내용, 주문정보(상품, 주소)</td>
                        </tr>
                        <tr>
                            <th scope="row">상품 구매(공통)</th>
                            <td>
                                <ul>
                                    <li>서비스 및 상품 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산</li>
                                    <li>회원 정보 자동 업데이트 및 관리</li>
                                    <li>고객 상담 및 불만, 민원 사무 처리</li>
                                    <li>재화 또는 서비스 제공</li>
                                    <li>성인 인증이 필요한 상품 구매 시 성인인증</li>
                                    <li>거래 안전을 위해 본인 인증 후 서비스 이용이 필요한 수 있는 서비스 제공</li>
                                    <li>판매자의 구매자와의 거래의 원활한 진행, 본인의사의 확인</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>주문자 정보(성명, 주소, 휴대폰 번호, 전화번호), 수령인 정보(성명, 주소, 휴대폰 번호, 전화번호), 구매 내역, 결제 내역</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">전통주 및 성인 인증 상품 구매/선물하기 서비스</th>
                            <td></td>
                            <td>성명, 휴대폰 번호, 생년월일, 성별, 연계정보(CI), 중복가입확인정보(DI)</td>
                        </tr>
                        <tr>
                            <th scope="row">공연/전시/티켓/여행 상품 구매</th>
                            <td></td>
                            <td>주문자 정보(성명, 휴대폰 번호)</td>
                        </tr>
                        <tr>
                            <th scope="row">선물하기 서비스</th>
                            <td></td>
                            <td>발신인 성명, 수신인 성명, 수신인의 휴대폰 번호, 수신인의 주소</td>
                        </tr>
                        <tr>
                            <th scope="row">현금 환불 요청</th>
                            <td></td>
                            <td>환불 계좌 정보(은행명, 계좌번호, 예금주)</td>
                        </tr>
                        <tr>
                            <th scope="row">성인 인증이 필요한 상품 수령 시 성인인증</th>
                            <td>
                                <ul>
                                    <li>서비스 및 상품 제공에 관한 계약 이행</li>
                                    <li>재화 또는 서비스 제공</li>
                                    <li>성인 인증이 필요한 상품 수령 시 수령자 식별 및 성인인증 고객 상담 및 불만, 민원 사무 처리</li>
                                </ul>
                            </td>
                            <td>성명, 휴대폰 번호, 생년월일, 연계정보(CI), 주소</td>
                        </tr>
                    </tbody>
                </_table3>
                <br />
                <p>제 2 조 [개인정보 보유 및 이용기간]</p>
                <br />
                <ul>
                    <li>회사는 법령에 따른 개인정보 보유·이용기간 또는 이용자로부터 개인정보 수집 시 동의받은 개인정보 보유·이용 기간 내에서 개인정보를 처리·보유합니다.</li>
                    <li>회사의 개인정보 처리 및 보유기간은 다음과 같습니다</li>
                    <li>
                        이용자의 회원 가입 및 관리 : 서비스 이용계약 종료시까지. 다만, 다음 사유에 해당하는 경우에는 해당 사유 종료시까지
                        <br />
                        가. 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료시까지
                        <br />
                        나. 서비스이용계약에 따른 채권·채무관계가 잔존하는 경우 정산시까지
                    </li>
                    <li>
                        이용자에 대한 서비스 제공 및 개선 : 서비스 이용계약 종료시까지. 단, 다음의 사유에 해당하는 경우에는 내부기준에 따른 보관기간 종료시까지.
                        <ul>
                            <li>부정이용 방지를 위해 정보(이름, 이메일(로그인ID), 휴대전화번호, CI/DI): 3년</li>
                            <li>이벤트 진행 및 경품 배송: 1년</li>
                            <li>
                                <span>다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지</span>
                                <_table4 border={1} role="table">
                                    <caption>관련 법령에 따른 보유 정보 및 보유 기간</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">관련법령</th>
                                            <th scope="col">보유 정보</th>
                                            <th scope="col">보유 항목</th>
                                            <th scope="col">보유기간</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">전자상거래 등에서의 소비자보호에 관한 법률</th>
                                            <td>표시·광고에 관한 기록</td>
                                            <td>광고 기록 등</td>
                                            <td>6개월</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">전자상거래 등에서의 소비자보호에 관한 법률</th>
                                            <td>계약 또는 청약철회 등에 관한 기록</td>
                                            <td>회원 식별정보, 계약 또는 청약철회 기록 등</td>
                                            <td>5년</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">전자상거래 등에서의 소비자보호에 관한 법률</th>
                                            <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                                            <td>회원 식별정보, 계약 또는 청약철회 기록 등</td>
                                            <td>5년</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">전자상거래 등에서의 소비자보호에 관한 법률</th>
                                            <td>소비자 불만 또는 분쟁처리에 관한 기록</td>
                                            <td>회원 식별 정보, 분쟁처리 기록 등</td>
                                            <td>3년</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">통신비밀보호법</th>
                                            <td>통신사실확인자료</td>
                                            <td>로그기록, IP 등</td>
                                            <td>3개월</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">국세기본법</th>
                                            <td>세법이 정하는 모든 거래에 관한 장부 및 증빙서류</td>
                                            <td>거래내역 및 증빙서류와 관련된 정보</td>
                                            <td>5년</td>
                                        </tr>
                                    </tbody>
                                </_table4>
                            </li>
                        </ul>
                    </li>
                </ul>
                <br />

                <p>제 3 조 [개인정보 제3자 제공]</p>
                <br />
                <ul>
                    <li>회사는 이용자의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.</li>
                    <li>
                        회사는 원활한 서비스 제공을 위해 다음의 경우 정보주체의 동의를 얻어 제3자에게 제공합니다.
                        <_table4 border={1} role="table">
                            <caption>제공받는 자 정보</caption>
                            <thead>
                                <tr>
                                    <th scope="col">제공받는 자</th>
                                    <th scope="col">제공 목적</th>
                                    <th scope="col">제공 항목</th>
                                    <th scope="col">보유 및 이용기간</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>회사의 등록된 판매자</td>
                                    <td>
                                        <ul>
                                            <li>상품 구매자 확인 및 상품 배송</li>
                                            <li>고객 상담 및 불만 처리</li>
                                            <li>환불처리</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>ID</li>
                                            <li>이메일</li>
                                            <li>휴대전화 번호</li>
                                            <li>상품 구매정보</li>
                                            <li>주소</li>
                                        </ul>
                                    </td>
                                    <td>상품 배송 후 90일</td>
                                </tr>
                            </tbody>
                        </_table4>
                    </li>
                </ul>
                <br />
                <p>제 4 조 [개인정보처리의 위탁]</p>
                <br />
                <ul>
                    <li>
                        회사는 원활한 업무 처리를 위하여 다음과 같이 개인정보 처리 업무를 위탁하고 있습니다.
                        <_table3 border={1} role="table">
                            <caption>위탁업체 정보</caption>
                            <thead>
                                <tr>
                                    <th scope="col">위탁업체</th>
                                    <th scope="col">위탁업무내용</th>
                                    <th scope="col">보유 및 이용 기간</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>(주)씨에스쉐어링</td>
                                    <td>고객 상담 및 CS 업무 처리</td>
                                    <td>회원 탈퇴 시 혹은 위탁계약 종료 시까지 단, 관계 법령의 규정에 따라 보존할 의무가 있으면 해당 기간 동안 보존</td>
                                </tr>
                                <tr>
                                    <td>(주)네이버파이낸셜, (주)카카오페이, (주)토스페이먼츠, (주)케이지이니시스</td>
                                    <td>상품 구매에 필요한 신용카드, 현금결제 등의 결제정보 전송이용자 결제 대행 및 간편 결제 서비스 제공</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>(주)케이지모빌리언스</td>
                                    <td>본인인증 및 신원확인</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>(주)케이지이니시스</td>
                                    <td>신용카드, 계좌이체 등 전자결제 서비스와 현금영수증 발행 서비스 제공</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>미니뱅크24</td>
                                    <td>자동입금확인 서비스 제공</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>(주)카카오</td>
                                    <td>카카오톡 알림톡 (정보성 메시지) 발송</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        (주)카카오엔터프라이즈 <br />
                                        <br />* 재위탁사 :<br />
                                        (주)카카오, (주)케이티, (주)LG유플러스, 세종텔레콤(주),(주)안랩
                                    </td>
                                    <td>문자 발송</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        씨제이대한통운(주) <br />
                                        <br />* 재위탁사 :<br />
                                        씨제이대한통운 개인정보처리방침 내 위탁사 전체
                                    </td>
                                    <td>물류서비스의 입고, 반출</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>카페24</td>
                                    <td>쇼핑몰 호스팅, 도메인 관리 서비스 등 제공</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </_table3>
                    </li>
                    <li>회사는 개인정보 처리 업무 위탁이 수반되는 계약의 체결시 위탁업무 수행목적 외의 개인정보 처리 금지, 기술적·관리적· 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 문서로서 명시하고, 수탁자가 개인정보를 안전하게 처리하는지 감독하고 있습니다.</li>
                    <li>위탁업무의 내용이나 수탁자가 변경될 경우 지체없이 본 개인정보 처리방침을 통하여 공개합니다.</li>
                </ul>
                <br />
                <p>제 5 조 [개인정보의 파기 절차 및 방법]</p>
                <br />
                <ul>
                    <li>원칙적으로 이용자의 개인정보는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기됩니다.</li>
                    <li>이용자로부터 동의를 받은 개인정보는 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 보관하는 경우에는, 해당 개인정보를 별도의 데이터베이스로 옮기거나 보관장소를 달리하여 보존합니다.</li>
                    <li>개인정보의 파기 절차 및 방법은 다음과 같습니다.</li>
                    <li>회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 해당 개인정보를 파기합니다.</li>
                    <li>회사는 다음 각 호의 방법으로 개인정보를 파기합니다.</li>
                    <li>종이에 기록, 저장된 개인정보 : 분쇄기로 분쇄하거나 소각을 통하여 파기</li>
                    <li>전자적 파일 형태로 저장된 개인정보: 기록을 재생할 수 없도록 로우레벨포맷(Low Level Format) 등 기술적 방법을 사용하여 삭제</li>
                </ul>
                <br />
                <p>제 6 조 [만 14세 미만 아동의 개인정보 처리]</p>
                <br />
                <ul>
                    <li>회사는 만 14세 미만 아동의 개인정보를 수집하지 않습니다. 다만, 회사가 만 14세 미만 아동의 개인정보를 수집하는 상황이 발생하는 경우에는, 법정대리인은 만 14세 미만 아동의 개인정보 수집·이용 또는 제공에 대한 동의를 철회할 수 있으며, 해당 아동이 제공한 개인정보에 대한 열람 또는 오류의 정정을 요구할 수 있습니다.</li>
                    <li>유선 또는 서면을 통하여 만 14세 미만 아동인 회원의 법정대리인이 열람, 증명을 요구하는 경우, 회사는 대리관계를 증명하는 위임장 및 인감증명서, 요청인 및 대리인의 신분증 사본 등의 증표를 제시 받아 해당 요구를 하는 자가 진정한 법정대리인인지 여부를 확인합니다.</li>
                </ul>

                <br />
                <p>제 7 조 [게시글에 대한 권리 및 의무]</p>
                <br />
                <ul>
                    <li>회사는 이용자의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다.</li>
                    <li>이용자의 게시물 등이 관련 법령에 위반되는 내용을 포함하거나 이용약관에 근거하여 서비스 내에 게시된 게시물 등이 사생활 침해 또는 명예훼손 등 제3자의 권리를 침해한다고 인정하는 경우 회사는 해당 관련 법령이나 적법한 권리자의 요청에 따라 해당 게시물 등에 대한 게시중단 및 삭제 등 조치를 취할 수 있습니다.</li>
                    <li>회사는 동의 없는 타인의 신상 공개 시 특정 부분을 삭제하거나 기호 등으로 수정하여 게시할 수 있습니다. 또한 게시물 이동 시 해당 게시물에 이동 경로를 밝혀 오해가 없도록 하고 있습니다. </li>
                    <li>근본적으로 게시물에 관련한 제반 관리와 책임은 작성자 개인에게 있습니다. 또한 게시물을 통해 자발적으로 공개된 정보는 보호받기 어려우므로 정보 공개 전에 심사숙고하시기 바랍니다. </li>
                </ul>

                <br />
                <p>제 8 조 [개인정보의 안전성 확보조치]</p>
                <br />
                <ul>
                    회사는 개인정보의 안전성 확보를 위하여 다음과 같은 조치를 취하고 있습니다.
                    <li>관리적 조치 : 내부관리계획의 수립 및 시행, 정기적 직원 교육</li>
                    <li>기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 비밀번호의 암호화, 보안프로그램의 설치 등</li>
                    <li>물리적 조치 : 자료보관실의 접근통제</li>
                </ul>

                <br />
                <p>제 9 조 [개인정보보호 책임자 및 담당자]</p>
                <br />
                <ul>
                    이용자는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 다음 각 호의 개인정보 보호책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
                    <li>
                        개인정보 보호책임자
                        <br />이 름 : 한혁진
                        <br />
                        전자메일 : hyeok_jin@ecfkorea.com
                    </li>
                    <li>
                        개인정보 보호 담당부서
                        <br />
                        부서명 : 정보보호부
                        <br />
                        전자메일 : hyeok_jin@ecfkorea.com
                    </li>
                </ul>

                <br />
                <p>제 10 조 [개인정보 열람 청구]</p>
                <br />
                <ul>
                    이용자는 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 이용자의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
                    <li>
                        부서명 : 정보보호부
                        <br />
                        전자메일 : hyeok_jin@ecfkorea.com
                    </li>
                </ul>

                <br />
                <p>제 11 조 [권익침해 구제 방법]</p>
                <br />
                <ul>
                    이용자는 다음 각 호의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
                    <li>
                        개인정보 침해신고센터 (한국인터넷진흥원 운영)
                        <br />
                        소관업무: 개인정보 침해사실 신고, 상담 신청
                        <br />
                        홈페이지: privacy.kisa.or.kr
                        <br />전 화: (국번없이) 118
                        <br />주 소: (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해 신고센터
                    </li>
                    <li>
                        개인정보 분쟁조정위원회 (개인정보보호위원회 운영)
                        <br />
                        소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
                        <br />
                        홈페이지: kopico.go.kr
                        <br />전 화: 1833-6972
                        <br />주 소: 03171 서울특별시 종로구 세종대로 209 정부서울청사 12층 개인정보 분쟁조정위원회
                    </li>
                    <li>대검찰청 사이버수사과: 1301 (spo.go.kr)</li>
                    <li>사이버범죄신고시스템: 182 (ecrm.police.go.kr)</li>
                </ul>

                <br />
                <p>제 12 조 [개인정보처리방침의 적용 제외]</p>
                <br />
                <p>
                    {`회사는 이용자에게 홈페이지를 통하여 다른 웹사이트 또는 자료에 대한
          링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대하여
          통제권이 없을 뿐만 아니라 이들이 개인정보를 수집하는 행위에 대하여
          회사의 '개인정보처리방침'이 적용되지 않습니다. 따라서, 회사가 포함하고
          있는 링크를 클릭하여 타 사이트의 페이지로 이동할 경우에는 새로 방문한
          사이트의 개인정보처리방침을 반드시 확인하시기 바랍니다.`}
                </p>
                <br />
                <ul>
                    부칙
                    <li>이 개인정보 처리방침은 2024년 12월 01일부터 적용됩니다.</li>
                    {/* <li>이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다. </li> */}
                </ul>
            </_div>

            <_btn onClick={() => clearModal()}>
                <IcModalClose size={32} color="#000" />
            </_btn>
        </_inner>
    );
};

export default PrivateTermModal;

'use client';
import { ModalContextDispatch } from '@/app/_common/modal/modalProvider/ModalContext';
import styled from '@emotion/styled';
import { useContext } from 'react';
import { _inner, _textarea, _p, _top, _btn } from '@/app/styles/components/modal/term/serviceTerm';

import useIcon from '@/app/_hook/useIcon';

const ServiceTermModal = () => {
    const { IcBack, IcModalClose } = useIcon();
    const { clearModal } = useContext(ModalContextDispatch);

    return (
        <_inner>
            <_top>
                <button onClick={() => clearModal()}>
                    <IcBack size={32} color="#000" />
                </button>
                <p>서비스 이용약관</p>
            </_top>
            <_p>서비스 이용약관</_p>
            <_btn onClick={() => clearModal()}>
                <IcModalClose size={32} color="#000" />
            </_btn>
            <_textarea
                defaultValue={`
제 1 장 총칙
제1조 (목적)
고객 서비스 이용 약관(이하 “이 약관”)은 주식회사 ECF(이하 “회사”)가 운영하는 사이버 몰에서 제공하는 인터넷 관련 서비스를 이용함에 있어 사이버 몰과 “고객”의 권리‧의무 및 책임사항을 규정함을 목적으로 합니다.

제2조 (정의)
① “몰”이란 회사가 재화 또는 용역(이하 “재화 등”)을 “고객”에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 사이버몰을 말합니다.
② “고객”이란 “몰”에 접속하여 이 약관에 따라 “회사”가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
③ “회원”이라 함은 “몰”에 회원 가입을 한 자로서, 계속적으로 “몰”이 제공하는 서비스를 이용할 수 있는 자를 말합니다.
④ “비회원”이란 회원으로 가입하지 않고 “몰”이 제공하는 서비스를 이용하는 자를 말합니다.
⑤ “판매자”란 “회사”와 “인터넷 쇼핑몰 입점 계약”을 체결하고 “몰”에서 재화 등을 판매하는 자를 말합니다.

제3조 (약관 등의 명시와 설명 및 개정)
① “회사”는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자 등을 “고객”이 쉽게 알 수 있도록 “몰”의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 “고객”이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
② “회사”는 “고객”이 약관에 동의하기에 앞서 약관에서 규정하고 있는 내용 중 청약철회, 배송책임, 환불조건 등과 같은 중요한 내용을 “고객”이 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 “고객”의 확인을 구하여야 합니다.
③ “회사”는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
④ “회사”는 이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 “몰”의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지하고 “고객”에게 통지합니다. 다만, “고객”에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지 및 통지합니다. 이 경우 “회사”는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 “고객”이 알기 쉽도록 표시합니다.
⑤ “고객”은 개정 약관이 게시되거나 통지된 후부터 변경되는 약관의 시행일 전의 영업일까지 개정 전 약관조항에 따라 체결된 계약을 해지할 수 있고, 약관의 개정내용에 대하여 이의를 제기하지 아니하는 경우에는 약관의 개정을 동의한 것으로 봅니다.
⑥ 회사는 제공하는 서비스 내의 개별 서비스에 대한 별도의 약관 또는 이용조건(이하 “기타 약관”이라 한다)을 둘 수 있으며, 이 약관의 동의와는 별개로 회원에게 기타 약관에 대한 동의를 받은 후 기타 약관을 적용합니다. 이 경우 기타 약관은 이 약관에 우선합니다.
⑦ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제 등에 관한 법률」, 「전자상거래 등에서의 소비자 보호지침」 및 관계법령 또는 상관례에 따릅니다.

제4조 (서비스의 제공 및 변경)
① “회사”는 다음과 같은 업무를 수행합니다.
1. 재화 등의 거래 및 정보 제공을 위한 플랫폼의 운영 및 개발 업무
2. 재화 등의 정보 검색 지원 업무
3. 재화 등의 구매 관련 지원 업무
4. 대금의 결제 및 정산 관련 업무
5. 기타 전자상거래와 관련하여 “회사”가 정하는 업무
② “회사”는 재화 등의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 등의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 등의 내용을 게시한 곳에 즉시 공지합니다.
③ “몰”이 제공하기로 “고객”과 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 “고객”에게 통지 가능한 주소로 즉시 통지합니다.
④ 제3항에 따라 서비스의 내용을 변경하여 “고객”에게 손해가 발생한 경우 “회사”는 그 손해를 배상합니다. 다만, “회사”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.

제5조 (서비스의 중단)
① “회사”는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
② “회사”는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 “고객” 또는 제3자가 입은 손해에 대하여 배상합니다. 다만, “회사”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “회사”는 제8조에 정한 방법으로 “고객”에게 통지하고 당초 “회사”에서 제시한 조건에 따라 “고객”에게 보상합니다. 

제6조 (회원가입)
① “고객”은 “회사”가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
② “회사”는 제1항과 같이 회원으로 가입할 것을 신청한 “고객” 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
1. 가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “몰”의 회원재가입 승낙을 얻은 경우에는 예외로 합니다.
2. 등록 내용에 허위, 기재 누락, 오기가 있는 경우
3. 기타 가입신청자를 회원으로 등록하는 것이 “몰”의 기술상 현저히 지장이 있다고 판단되는 경우
③ 회원가입계약의 성립 시기는 “회사”의 승낙이 가입 신청자에게 도달한 시점으로 합니다.
④ “회원”은 회원가입 시 등록한 사항에 변경이 있는 경우, 일정 기간 이내에 “회사”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
⑤ “회사”는 「영화 및 비디오물의 진흥에 관한 법률」, 「청소년 보호법」 및 「화학물질 관리법」 등 관련 법령에 따른 등급 및 연령제한 준수를 위하여 “고객”의 서비스 이용 또는 상품 등 구매를 제한하거나, “고객”에게 나이 및 본인 확인 등의 절차를 요구할 수 있습니다.

제7조 (회원 탈퇴 및 이용의 제한)
① “회원”은 “회사”에 언제든지 탈퇴를 요청할 수 있으며 “회사”는 즉시 회원탈퇴를 승낙합니다.
② “회원”이 다음 각 호의 사유에 해당하는 경우, “회사”는 회원자격을 제한 및 정지시킬 수 있습니다.
1. 가입 신청 시에 허위 내용을 등록한 경우
2. “몰”을 이용하여 구입한 재화 등의 대금, 기타 “몰” 이용에 관련하여 “회원”이 부담하는 채무를 기일에 지급하지 않는 경우 
3. 다른 사람의 “몰” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
4. “몰”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
5. 기타 서비스 운영을 고의로 방해하는 행위를 하는 경우
③ “회사”가 회원 자격을 제한‧정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “회사”는 회원자격을 상실시킬 수 있습니다.
④ “회사”가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 “회원”에게 이를 통지하고, 회원등록 말소 전 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
⑤ 제2항부터 제4항까지의 규정에도 불구하고, 다음의 각 호 중 어느 하나에 해당하는 경우 “회사”는 그 사실을 알게 된 날로부터 3일 내에 “회원” 및 “고객”에게 그 위반 사실을 통보한 후 회원자격을 상실시키거나 “회사”의 서비스 이용을 영구히 제한할 수 있습니다. 이 경우 “회원” 및 “고객”에게 이를 통지하고, 회원등록 말소 혹은 서비스 영구 제한 전 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
1. 공급받은 재화 등을 고의로 훼손하여 청약철회 등을 요구하는 경우
2. 타인의 명의나 정보를 도용하여 서비스를 이용하는 경우
3. 여신전문금융업법 등 관련 법령을 위반하여 비정상적인 결제를 하거나 기타 “회사”의 시스템을 비정상적으로 이용하는 경우
4. 정당하지 않은 사유로 주문의 취소, 반품, 환불 등을 반복하여 “회사”의 업무를 방해하는 경우
5. 범죄 또는 현금화 목적 등으로 대금 결제 방식을 악용하는 행위
6. “회사”의 직원에게 폭언, 폭행 등 적정 범위를 벗어나 신체적, 정신적 고통을 유발할 수 있는 행위를 하는 경우
7. 그 밖에 통상적이지 않은 방법으로 “몰”을 이용하여 “회사”의 업무와 다른 “회원” 및 “고객”의 서비스 이용을 방해하여 그 권리와 이익을 현저히 침해하는 경우

제8조 (회원에 대한 통지)
① “회사”는 “회원”에 대한 통지를 하는 경우, 회원이 “회사”와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다. “회원”은 자신이 지정한 전자우편 주소에 변경이 생긴 경우 이를 “몰”에서 정한 방법 및 절차에 따라 이를 수정하여야 하며, “회원”이 이를 게을리하여 “회사”가 원래의 전자우편 주소로 통지를 한 경우 그러한 통지는 유효하게 도달된 것으로 봅니다.
② “회사”는 불특정다수 회원에 대한 통지의 경우 1주일 이상 “몰” 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.

제9조 (회사의 의무)
① “회사”는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화 등을 제공하는데 최선을 다하여야 합니다.
② “회사”는 “고객”이 안전하게 인터넷 서비스를 이용할 수 있도록 “고객”의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
③ “회사”는 “회원”으로부터 제기되는 의견이나 불만이 객관적으로 정당하다고 인정될 경우 “회원”의 불만을 해결하기 위해 노력하며, 그에 따른 보상을 할 수 있습니다.
④ “회사”는 “고객”이 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.

제10조 (회원의 ID 및 비밀번호에 대한 의무)
① ID와 비밀번호에 관한 관리책임은 “회원”에게 있습니다.
② “회원”의 계정을 통해 일어난 구매, 게시글 작성 등의 모든 활동은 계정을 소유한 “회원”의 활동으로 간주합니다. 따라서 “회원”은 본인의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
③ “회원”이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “회사”에 통보하고 “회사”의 안내가 있는 경우에는 그에 따라야 합니다.
④ “회사”는 “회원”이 제1항, 제2항, 제3항을 위반하여 “회원”에게 발생한 손해에 대하여 그 손해 발생의 원인이 “회사”의 고의 또는 과실에 기인하지 않는 한 책임을 부담하지 않습니다.

제11조 (고객의 의무)
① 고객은 이 약관의 규정, 회사의 이용정책, 이용안내 사항 및 관련 법령 등을 준수하여야 합니다.
②”고객”은 다음 각 호의 행위를 하여서는 안 됩니다.
1. 회원 가입의 신청 또는 변경 시 허위 내용을 등록하는 행위
2. 타인의 명의, 카드정보, 계좌정보, 주소정보 등 정보를 도용하여 “회사”가 제공하는 서비스를 이용하는 행위
3. “몰”에 게시된 정보를 무단으로 변경하는 행위
4. “회사”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등을 송신 또는 게시하는 행위
5. “회사” 또는 제3자의 저작권 등 지적재산권을 침해하는 행위
6. “회사” 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위
7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 “몰”에 공개 또는 게시하는 행위
8. 동일한 상품을 5회 이상 반복적으로 주문 취소한 후 재주문하는 행위
9. 고객응대근로자에게 폭언, 욕설을 하는 행위
10. 기타 공서양속에 위배되는 불법적이거나 부당한 행위

제12조 (대금 지급방법)
① “몰”에서 구매한 재화 등의 대금 지급은 다음 각 호의 어느 하나의 방법으로 할 수 있고, “회사”가 필요로 하는 경우 재화 등의 대금 지급 방법을 각 호의 방법 중 “회사”가 정한 방법으로 결정할 수 있습니다. 단, “회사”는 “고객”의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
3. 온라인 무통장입금
4. 전자화폐에 의한 결제 (휴대폰 결제, 온라인 결제서비스를 통한 결제 등)
5. ECF적립금 등 “몰”이 지급한 적립금에 의한 결제
6. “몰”과 계약을 맺었거나 “몰”이 인정한 상품권에 의한 결제
7. 기타 전자적 지급 방법에 의한 대금 지급 등
② 구매대금의 결제와 관련하여 “고객”이 입력한 정보 및 그와 관련된 책임은 “고객”에게 있으며, 재화 또는 용역의 청약 후 합리적인 일정 기간 내에 결제가 이루어 지지 않는 경우 “회사”는 해당 주문을 취소할 수 있습니다.
③ “회사”는 구매자의 결제수단에 대하여 정당한 사용권한 보유여부를 확인할 수 있으며 필요한 경우 해당 거래진행의 정지 및 소명자료의 제출을 요청할 수 있습니다.


제13조 (구매안전서비스의 제공)
① “회사”는 “고객”이 재화 등을 공급받기 전에 제12조 제2호 및 제5호를 제외한 방법으로 재화 등의 대금을 지급하는 경우, 해당 결제 대금을 제3자에게 예치하고 공급이 완료된 후 “회사” 또는 “판매자”에게 지급하도록 하는 ‘구매안전 서비스’를 제공합니다.
②”고객”은 “회사”가 ‘구매안전 서비스’를 제공하는 과정에서 발생하는 이자 등에 대하여 “회사”에게 그 반환을 청구할 수 없습니다.
③ '구매안전 서비스’는 제25조에 정한 바에 따라 구매가 확정되고, 정산이 완료된 경우에 종료됩니다.
“고객”은 ‘구매안전 서비스’가 종료된 이후에 해당 구매 건에 대하여 청약철회, 취소, 해제, 무효 등의 사유가 발생한 경우 “판매자”와 직접 청약철회, 취소, 해제 및 환불 등에 관한 절차를 진행해야 합니다.                                        

제14조 (ECF 적립금)
① “회사”는 “회원”이 구매 활동 ‧ 구매후기 작성 ‧ 이벤트 참여를 하거나, 출고지연 ‧ 주문 후 품절 ‧ 청약철회 등의 처리 지연을 당한 경우 “회사”가 정한 기준에 따라 일정한 “ECF 적립금”을 부여할 수 있습니다.
② “회원”은 “몰”에서 “상품”을 구매 시 다른 결제 수단과 함께 “ECF 적립금”을 사용할 수 있으며, “ECF 적립금”은 최대 상품금액의 7%까지 사용 가능합니다.
③ “회사”는 “ECF 적립금”의 적립기준, 사용방법, 사용기한 및 제한 등을 상품, 회원등급 및 지급 사유 등에 따라 다르게 정할 수 있으며, “몰”의 화면에 별도로 게시하거나 통지합니다.
④ “ECF 적립금”은 1) 사용기한이 먼저 도래하는 순서 2) 사용기한이 동일한 경우 적립일자가 빠른 순서대로 사용됩니다.
⑤ “회원”은 “회사”가 별도로 허용한 경우를 제외하고는 “ECF 적립금”을 제3자에게 또는 다른 아이디로 양도할 수 없으며 유상으로 거래하거나 현금으로 전환할 수 없습니다.
⑥ “회원”이 “회사”가 승인하지 않은 방법 또는 허위 정보 제공, 본 약관에 위배되는 등의 부정한 방법으로 “ECF 적립금”을 획득하거나, 부정한 목적이나 용도로 “ECF 적립금”을 사용하는 경우, “회사”는 “ECF 적립금”의 사용을 제한하거나 “회사”가 정한 방법으로 회수할 수 있으며, “ECF 적립금”을 사용한 구매신청을 취소하거나 “회원” 자격을 정지할 수 있습니다.
⑦ “회원” 탈퇴 시 또는 “회원”이 “몰”에 마지막으로 로그인한 날로부터 1년이 경과할 경우(이하 “휴면상태”) 그 때까지 사용하지 않은 “ECF 적립금”은 즉시 소멸되며, 탈퇴 후 재가입하거나 다시 로그인을 하더라도 소멸된 “ECF 적립금”은 복구되지 아니합니다.


제15조 (개인정보보호)
① “회사”는 “고객”의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
② “회사”는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
③ “회사”는 “고객”의 개인정보를 수집‧이용하는 때에는 “고객”에게 그 목적을 고지하고 동의를 받습니다.
④ “회사”는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용‧제공단계에서 “고객”에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정하고 있는 경우에는 예외로 합니다.
⑤ “회사”가 제3항과 제4항에 의해 “고객”의 동의를 받아야 하는 경우에는 개인정보보호 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 관련 법령이 규정한 사항을 미리 명시하거나 고지해야 하며 “고객”은 언제든지 이 동의를 철회할 수 있습니다.
⑥ “고객”은 언제든지 “회사”가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “회사”는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다.
⑦ “회사”는 개인정보 보호를 위하여 “고객”의 개인정보를 처리하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 “고객”의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 “고객”의 손해에 대하여 모든 책임을 집니다.
⑧ “회사”는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
⑨ “회사”는 개인정보의 수집‧이용‧제공에 대해 “고객”이 동의를 거절하는 경우 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집‧이용‧제공에 관한 “고객”의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.

제 2 장 통신판매서비스
제16조 (구매신청 등)
“고객”은 “몰” 상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “회사”는 “고객”이 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
1. 재화 등의 검색 및 선택
2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
3. 약관 내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한 확인
4. 이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예: 마우스 클릭)
5. 재화 등의 구매신청 및 이에 관한 확인 또는 “몰”의 확인에 대한 동의
6. 결제 방법의 선택 및 결제

제17조 (계약의 성립 등)
① 재화 등의 매매계약은 “고객”이 청약의 의사표시를 하고 이에 대해 “회사”가 승낙의 의사표시를 함으로써 체결됩니다.
② “회사”는 제1항에 따른 승낙의 의사 표시는 “몰” 내에서 “고객”에게 제공되는 팝업 기타 안내 화면, 전자문서 등을 통한 수신확인 통지의 방법으로 하며, 계약의 성립 시기는 수신확인 통지가 “고객”에게 도달한 때입니다.
③ “회사”의 승낙의 의사표시에는 “고객”의 구매 신청에 대한 확인 및 판매 가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.
④ 수신확인통지를 받은 “고객”은 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고, “회사”는 배송 시작 전에 “고객”의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 제20조의 청약철회 등에 관한 규정에 따릅니다.
⑤ 계약이 성립한 후 회사가 제6항 각 호의 사유를 발견한 경우 “회사”는 즉시 계약을 해제 또는 취소할 수 있으며, 계약 해제 또는 취소 시 회사는 회원이 상품 대금을 지급한 날로부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
⑥ “회사”는 제16조에 따른 구매 신청에 대하여 다음 각 호에 해당하는 경우 구매 신청을 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
1. 신청 내용에 허위, 기재 누락, 오기가 있는 경우
2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 등을 구매하는 경우
3. 제7조에 따라 회원 자격이 제한 또는 상실된 회원이 구매신청을 한 경우
4. 기타 구매 신청에 승낙하는 것이 “회사”의 기술상 현저히 지장이 있다고 판단하는 경우

제18조 (재화 등의 공급)
① “회사”는 “고객”과 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, “고객”이 청약을 한 날부터 7일 이내에 재화 등의 공급에 필요한 조치를 하여야 합니다. 다만, “고객”이 재화 등을 공급받기 전에 미리 재화 등의 대금을 전부 또는 일부 지급한 경우 “고객”이 그 대금을 지급한 날부터 3영업일 이내에 재화 등의 공급에 필요한 조치를 취합니다.
② “회사”는 “고객”이 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 안내합니다.
③ 공휴일 및 기타 휴무일 또는 천재지변 등의 불가항력적 사유가 발생하는 경우 그 기간은 배송소요기간에서 제외합니다.

제19조 (환급)
“회사”는 “고객”이 청약을 한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 “고객”에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.

제20조 (청약철회 등)
① “회사”와 재화 등의 구매에 관한 계약을 체결한 “고객”은 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 해당 계약에 대한 청약의 철회 및 계약의 해제(이하 “청약철회 등”)를 할 수 있습니다. 다만, 청약철회 등에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
② “고객”은 다음 각 호의 어느 하나에 해당하는 경우에는 “회사”의 의사에 반하여 청약철회 등을 할 수 없습니다.
1. “고객”에게 책임 있는 사유로 재화 등이 멸실되거나 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외합니다)
2. “고객”의 사용 또는 일부 소비로 재화 등의 가치가 현저히 감소한 경우
3. 시간이 지나 다시 판매하기 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우
4. 복제가 가능한 재화 등의 포장을 훼손한 경우
5. 용역 또는 디지털콘텐츠의 제공이 개시된 경우. 다만, 가분적 용역 또는 가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에 대하여는 그러하지 아니합니다.
6. “고객”의 주문에 따라 개별적으로 생산되는 재화 등으로 청약철회 등을 인정하는 경우 “회사”에게 회복할 수 없는 중대한 피해가 예상되는 경우로서 사전에 해당 거래에 대해 별도로 그 사실을 고지하고 소비자의 서면(전자문서를 포함한다)에 의한 동의를 받은 경우
③ “회사”가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명시하거나 시용 상품을 제공하는 등의 조치를 하지 않은 경우 제2항 제2호부터 제5호의 규정에도 불구하고 “고객”의 청약철회 등이 제한되지 않습니다.
④ “고객”은 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시‧광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 해당 재화 등을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.

제21조 (청약철회 등의 효과)
① “회사”는 “고객”으로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 “회사”가 “고객”에게 재화 등의 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의3에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
② “회사”는 위 대금을 환급함에 있어서 “고객”이 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 해당 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 “고객”이 부담합니다. “회사”는 “고객”에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시‧광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 “회사”가 부담합니다.
④ “고객”이 재화 등을 제공받을 때 발송비를 부담한 경우에 “회사”는 청약철회 시 그 비용을 누가 부담하는지를 “고객”이 알기 쉽도록 명확하게 표시합니다.

제22조 (긴급조치)
① “회사”는 “고객”이 위법, 불법, 타인의 권리 침해 그 밖에 부당한 목적을 위해 서비스를 이용한다고 판단하는 경우 그 “고객”과 관련된 물품의 수취나 배송을 거절할 권리를 가집니다.
② “회사”가 제공하는 재화 등이 관할 관청 또는 당국에 의해 제재를 받았을 때 “회사”는 해당 재화 등을 관할 관청 또는 당국에 인도하는 것을 원칙으로 합니다. 이로 인하여 “고객”이 손해를 입었다고 할지라도, “회사”의 고의 또는 과실이 없는 한 해당 손해에 대해서 “회사”는 책임을 지지 않습니다.
③ “회사”의 주소로 배송된 물품에 악취, 액체 누수 등 그 밖에 정당한 사유가 있다고 인정이 되어 긴급을 필요로 하는 경우 “회사”는 “고객”에게 해당 사실을 통지하고 해당 물품을 별도 장소로 이동 보관하는 등 임시조치를 취할 수 있습니다. 이로 인해 발생하는 추가비용은 “고객”이 부담하여야 하며 또한 “고객”에게 손해가 발생하더라도 해당 손해에 대하여 책임을 지지 않습니다.

제 3 장 통신판매중개서비스
제23조 (서비스의 이용 및 책임의 제한)
① 통신판매중개서비스와 관련하여 “회사”는 통신판매중개자로서 “고객”과 “판매자” 간의 자유로운 재화 등의 거래를 위한 시스템을 운영, 관리 및 제공하며 “고객”은 재화 등을 구매하기 전에 반드시 “판매자”가 “몰” 내에 작성한 재화 등의 상세 내용과 거래 조건을 확인해야 합니다.
② “회사”는 관련 법령에 따라 “회사”가 부담해야하는 책임 및 “몰”의 운영 및 관리 책임을 부담하고, “몰”에서의 “판매자”와 “고객” 간의 재화 등의 거래와 관련하여 “고객” 또는 “판매자”를 대리하지 아니하며, “회사”의 고의 또는 과실이 없는 이상 “고객”과 “판매자” 간 재화 등의 거래에 대한 위험과 책임은 거래 당사자가 부담합니다.
③ “회사”는 관련 법령에 따라 “회사”가 책임을 부담하는 경우를 제외하고는 “몰”에서의 “판매자”와 “고객” 간의 상품 거래와 관련하여 거래당사자의 판매 의사 또는 구매 의사의 존부 및 진정성, 상품의 품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, “판매자”가 제공하거나 등록한 정보 및 자료의 진실성 또는 적법성 등을 보증하지 아니하며, “회사”의 고의 또는 과실이 없는 이상 “고객”과 “판매자” 간의 상품 거래에 관한 위험과 책임은 거래 당사자가 부담합니다.

제24조 (계약의 성립 등)
① 통신판매중개서비스와 관련하여, 재화 등의 매매계약은 “고객”이 “판매자”의 계약 조건에 응하여 청약의 표시를 하고 이에 대해 “판매자”가 승낙의 의사표시를 함으로써 체결됩니다.
② 청약의 승낙 방법과 내용, 계약 성립의 시기, 의사의 불일치에 따른 조치 등은 제17조의 규정을 준용하며, 이 때 “회사”는 “판매자”로 봅니다.

제25조 (재화 등의 공급)
① “회사”는 “고객”의 대금 결제에 대한 확인 통지를 받은 날로부터 3영업일 이내에 “판매자”에게 배송에 필요한 조치를 취하도록 안내합니다.
② 재화 등의 배송과 관련하여 “고객”과 “판매자”, 배송업체, 금융기관 등 사이에 분쟁이 발생하는 경우 당사자들 간에 해결하는 것을 원칙으로 하며, 이와 관련하여 “회사”의 고의 또는 과실이 없는 한 “회사”는 어떠한 책임도 부담하지 않습니다.
③ “고객”이 재화 등의 배송완료일로부터 7일 이내에 교환 또는 반품에 대한 의사표시를 하지 않으면 “회사”는 “고객”에게 해당 거래에 대하여 구매 확정의 의사가 있는 것으로 간주하고 배송상태를 자동으로 배송 완료로 전환할 수 있습니다. 단, “고객”은 실제로 재화 등을 수령하지 못한 경우 미수취신고를 할 수 있습니다.
제26조 (청약철회 등)
① “회사”는 “고객”으로부터 청약철회 등의 의사표시를 받은 경우 즉시 그 사실을 “판매자”에게 통보합니다.
② 청약철회 등에 필요한 배송비 및 기타 비용은 귀책사유가 있는 당사자가 부담합니다. 이 경우 재화 등의 하자 또는 오배송으로 인한 청약철회 등은 “판매자”가 그 비용을 부담하고, “고객”의 단순 변심으로 인한 청약철회 등은 “고객”이 그 비용을 부담합니다.
③ “고객”의 청약철회 등의 신청에 대하여 “판매자”가 “고객”의 신청일로부터 30일 이내에 자신의 업무를 이행하지 않는 경우 “회사”는 “고객”의 청약철회 등이 제20조에 따라 정당한 것인지 확인하고, 해당 거래를 취소하는 한편 결제대금을 “고객”에게 환불할 수 있습니다.
④ 이외에 청약철회의 조건, 제한의 사유 및 관련 표시 등은 제20조의 규정을 준용하며, 이 때, “회사”는 “판매자”로 봅니다.

제27조 (금지행위)
“고객”은 “회사”가 제공하는 서비스를 이용하지 아니하고 “판매자”와 직접 거래하는 행위를 할 수 없습니다. 이 경우 직접 거래를 통해 발생한 문제에 대한 책임은 거래 당사자에게 있으며, “회사”는 어떠한 책임도 부담하지 않습니다.

제28조 (분쟁의 해결)
① “회사”는 “고객”이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치‧운영합니다.
② “회사”는 “고객”으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 “고객”에게 그 사유와 처리일정을 즉시 통보합니다.
③ “회사”와 “고객” 간에 발생한 전자상거래 분쟁과 관련하여 “고객”의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시‧도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
④ “회사”는 “고객”과 “판매자” 간에 발생하는 분쟁을 객관적이고 원활하게 조정하기 위해 분쟁조정기구를 운영합니다.
⑤ “고객”은 분쟁조정기구의 조정에 신의성실의 원칙에 따라 성실히 응해야 합니다.



제 4 장 기타 사항
제29조 (저작권의 귀속 및 이용제한)
① “회사”가 작성한 저작물에 대한 저작권 및 기타 지적재산권은 “회사”에 귀속합니다.
② “고객”은 “몰”을 이용함으로써 얻은 정보 중 “회사”에게 지적재산권이 귀속된 정보를 “회사”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
③ “고객”이 “회사”의 서비스를 이용하면서 작성한 상품평, Q＆A 등 게시물의 저작권은 해당 “고객” 본인에게 있으며, 해당 게시물이 타인의 저작권 및 기타 지적재산권을 침해하는 경우 회사의 고의 또는 과실이 없는 한 그에 대한 책임은 “고객” 본인이 부담합니다.
④ “회사”는 게시물이 다음 각 호의 어느 하나에 해당하는 경우, 사전 통보 없이 해당 게시물을 삭제하거나 다른 “고객”이 볼 수 없도록 임시조치를 취할 수 있으며 “몰”의 이용이 제한될 수 있습니다. 단, “회사”는 게시물이 단순히 “회사” 또는 “판매자”에게 불리하다는 이유만으로 삭제 또는 임시조치를 취하지 않으며 회사가 임시조치를 취하거나 “몰”의 이용을 제한하는 경우 “고객”은 회사에 30일 내 이의를 제기하여 구제받을 수 있습니다.
1. 대한민국 법령을 위반하는 내용을 포함하는 경우
2. 관계법령에 의해 판매가 금지된 불법제품 또는 음란물을 게시하거나 광고하는 경우
3. 허위 또는 과장광고의 내용을 포함하는 경우
4. 타인의 권리, 명예, 신용 및 기타 정당한 권리를 침해하는 경우
5. 직거래 유도 또는 다른 사이트의 링크를 게시하는 경우
6. 정보통신기기의 오작동을 일으킬 수 있는 악성코드, 데이터 등을 포함하는 경우
7. 사회 공공질서 및 미풍양속에 위배되는 경우
8. “회사”가 제공하는 서비스의 원활한 운영을 방해하는 것으로 판단되는 경우
9. 범죄행위와 관련된 내용을 포함하는 경우
10. 정치, 경제적 분쟁을 야기하는 내용을 포함하는 경우
⑤ “고객”이 “몰” 내에 게시한 저작물을 “회사”가 국내 또는 국외에서 다음 각호의 목적에 따라 필요한 범위 내에서 해당 콘텐츠를 복제, 배포, 전송, 전시할 수 있으며, 본질적인 내용을 변경하지 않는 범위 내에서 수정, 편집, 번역할 수 있습니다. 다만, “고객”은 언제든지 해당 저작물의 삭제, 비공개 등의 조치를 요청할 수 있습니다.
1.”몰” 또는 “회사”가 운영하는 웹 사이트에서 “저작물”을 사용하거나, 홍보하기 위한 목적으로 인터넷, 모바일, SNS를 포함한 마케팅
2. “회사”의 제휴서비스(“회사”의 계열회사 서비스 포함)에 콘텐츠 제공 및 사용

제30조 (재판권 및 준거법)
① “회사”와 “고객” 간에 발생한 전자상거래 분쟁에 관한 소송은 민사소송법에 따라 그 관할법원을 정합니다.
② “회사”와 “고객” 간에 제기된 전자상거래 소송에는 대한민국의 법률을 적용합니다.

부칙
이 약관은 2024년 [*]월 [*]일부터 적용됩니다.`}
                readOnly={true}
            />
        </_inner>
    );
};

export default ServiceTermModal;

import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaDown, mediaUp } from '@/app/styles/utils';

export const _inner = styled.div`
    position: relative;
    width: 640px;
    max-height: 808px;
    height: 80vh;
    background-color: ${theme.colors.white};
    font-size: ${theme.fontSize.small};
    line-height: ${theme.lineHeight.normal};
    font-family: ${theme.fontFamily.body};
    color: ${theme.colors.black};

    li {
        width: calc(100% - 24px);
        list-style: disc;
        margin-left: 24px;
        margin-bottom: 8px;

        ul li {
            list-style-type: circle;
        }
    }

    @media screen and (max-width: 1026px) {
        width: 100vw;
        max-height: none;
        height: 100vh;
        height: 100dvh;
        padding-top: 48px;
    }
`;

export const _p = styled.p`
    font-size: 24px;
    line-height: ${theme.lineHeight.normal};
    font-weight: ${theme.fontWeight.medium};
    padding: 24px 16px;
    /* border-bottom: 1px solid #eee; */

    @media screen and (max-width: 1026px) {
        display: none;
    }
`;

export const _div = styled.div`
    width: 100%;
    height: calc(100% - 133px);
    padding: 0 20px 32px;
    overflow-y: auto;

    p {
        font-size: 14px;
        font-weight: 400;
        color: #111;
        margin: 5px 0 0px;

        :first-of-type {
            margin: 20px 0 0px;
        }
    }

    br {
        display: block;
        margin: 8px 0;
    }

    @media screen and (max-width: 1026px) {
        width: 100%;
        height: calc(100vh - 48px);
        padding: 20px 16px 32px;
    }
`;

export const _top = styled.div`
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    background: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;

    @media screen and (max-width: 1026px) {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        font-weight: 500;
        line-height: 1.4;
        color: #111;
    }

    > button {
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
            width: 24px;
            height: 24px;
        }
    }
`;

export const _btn = styled.button`
    position: absolute;
    right: 16px;
    top: 24px;
    width: 32px;
    height: 32px;

    @media screen and (max-width: 1026px) {
        display: none;
    }
`;

export const _table3 = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 16px 0;
    background: white;

    caption {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
    }

    th,
    td {
        border: 1px solid #e0e0e0;
        padding: 12px 16px;
        text-align: left;
        vertical-align: top;
        line-height: 1.5;
    }

    th {
        background-color: #f8f9fa;
        font-weight: 500;
        color: #111;
    }

    td {
        color: #333;
    }

    th:nth-of-type(1),
    td:nth-of-type(1) {
        width: 30%;
    }

    th:nth-of-type(2),
    td:nth-of-type(2) {
        width: 40%;
    }

    th:nth-last-of-type(1),
    td:nth-last-of-type(1) {
        width: 30%;
    }

    ul {
        margin: 0;
    }

    li {
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const _table4 = styled(_table3)`
    th,
    td {
        width: 25% !important;
    }
`;

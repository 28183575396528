import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaDown, mediaUp } from '@/app/styles/utils';

export const _inner = styled.div`
    position: relative;
    width: 640px;
    width: 640px;
    max-height: 808px;
    height: 80vh;
    background-color: ${theme.colors.white};
    font-size: ${theme.fontSize.small};
    line-height: ${theme.lineHeight.normal};
    font-family: ${theme.fontFamily.body};
    color: ${theme.colors.black};

    @media screen and (max-width: 1026px) {
        width: 100vw;
        max-height: none;
        height: 100vh;
        height: 100dvh;
        padding-top: 48px;
    }
`;

export const _textarea = styled.textarea`
    width: 100%;
    height: calc(100% - 133px);
    overflow-y: auto;
    padding: 0px 16px 16px;
    overflow-y: auto;
    resize: none;
    font-size: ${theme.fontSize.small};
    line-height: ${theme.lineHeight.normal};
    font-family: ${theme.fontFamily.body};
    @media screen and (max-width: 1026px) {
        width: 100vw;
        height: calc(100vh - 48px);
        padding-top: 16px;
    }
`;

export const _p = styled.p`
    font-size: 24px;
    line-height: ${theme.lineHeight.normal};
    font-weight: ${theme.fontWeight.medium};
    /* border-bottom: 1px solid ${theme.colors.textDarker[50]}; */
    padding: 24px 16px;
    @media screen and (max-width: 1026px) {
        display: none;
    }
`;

export const _top = styled.div`
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    @media screen and (max-width: 1026px) {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: black;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        z-index: 10;
    }
    > button {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 44px;
        height: 44px;
        > img {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
`;

export const _btn = styled.button`
    position: absolute;
    right: 16px;
    top: 24px;
    width: 32px;
    height: 32px;

    @media screen and (max-width: 1026px) {
        display: none;
    }
`;
